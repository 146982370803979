<template>
  <Popup
    :title="isModify? '바코드 수정' : '새로운 바코드 추가'"
    :maxWidth="1000"
    closeBtnText="닫기"
    :isDisabeldCompeleteBtn="vaild"
    compeleteBtnText="저장"
    @onClickClose="$emit('onClickPopupClose')"
    @onClickComplete="onClickPopupComplete()">
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:160px"/>
        <col />
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>상태<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td colspan="3">
            <Selectbox
              :dataList="'barcode_status' | getSelectDataList"
              :value.sync="dataViewModel.status"
              maxWidth="300px"/>
          </td>
        </tr>
        <tr>
          <th>결제 카테고리<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <Selectbox
              :dataList="'barcode_payment_category' | getSelectDataList"
              :value.sync="dataViewModel.payment_category"
              thumnailKey="imageUrl"
              maxWidth="300px"/>
          </td>
          <th>페이백<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <Input
              placeholder="페이백 퍼센트값을 입력해주세요."
              :value.sync="dataViewModel.payback_percent"
              valueType="numberCommaDot"
              :isBlockUnValueType="true"/>
          </td>
        </tr>
        <tr>
          <th>브랜드코드<IconSvg v-if="!isModify" iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <span v-if="isModify" class="txt_tbl">{{ dataViewModel.brand_code }}</span>
            <Input
              v-else
              placeholder="브랜드코드를 입력해주세요."
              :maxLength="6"
              isCount
              :value.sync="dataViewModel.brand_code"/>
          </td>
          <th>상품코드<IconSvg v-if="!isModify" iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <span v-if="isModify" class="txt_tbl">{{ dataViewModel.goods_code }}</span>
            <Input
              v-else
              placeholder="상품코드를 입력해주세요."
              :maxLength="20"
              isCount
              :value.sync="dataViewModel.goods_code"/>
          </td>
        </tr>
        <tr>
          <th>아이콘<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td colspan="3">
            <FileUpload
              :dataList.sync="dataViewModel.brand_logo_img_list"
              :canMultiple="false"
              :acceptList="['jpg','jpeg','png']"
              :size="[120,120]"
              sizeType="FIT"
              :saveTrigger="saveTrigger"
              :isSaved.sync="isSaved"/>
          </td>
        </tr>
        <tr>
          <th>브랜드명<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td colspan="3">
            <Input
              placeholder="브랜드명을 입력해주세요."
              :value.sync="dataViewModel.brand_name"/>
          </td>
        </tr>
        <tr>
          <th>이용안내<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td colspan="3">
            <Textarea
              placeholder="이용안내 내용을 입력해주세요."
              :value.sync="dataViewModel.use_info"
              :showLine="10"/>
              <p class="desc_info">💡 리스트 항목은 줄 바꿈을 기준으로 자동 구분합니다</p>
          </td>
        </tr>
        <tr>
          <th>이용 매장 안내</th>
          <td colspan="3">
            <Input
              placeholder="이용 매장 안내를 입력해주세요."
              :value.sync="dataViewModel.use_branch_info"/>
          </td>
        </tr>
      </template>
    </TableView>
  </Popup>
</template>

<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Selectbox from '@lemontree-ai/lemontree-admin-common-front/components/common/selectbox/Selectbox';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import FileUpload from '@lemontree-ai/lemontree-admin-common-front/components/common/file/FileUpload';
import Textarea from '@lemontree-ai/lemontree-admin-common-front/components/common/textarea/Textarea';
import { pageValidated } from '@lemontree-ai/lemontree-admin-common-front/utils/validateUtils';
import BrandModel from '@/views/service/barcode/mapping/model/BrandModel';
// mixin
import FileSaveMixin from '@lemontree-ai/lemontree-admin-common-front/mixins/FileSaveMixin'

export default {
  name:'BrandRegisterPopup',
  mixins:[FileSaveMixin],
  components:{
    Popup,
    TableView,
    IconSvg,
    Selectbox,
    Input,
    FileUpload,
    Textarea
  },
  props:{
    mappingViewModel: Object,
  },
  data(){
    return{
      dataViewModel: new BrandModel(),
    }
  },
  computed:{
    isModify(){
      return this.mappingViewModel.dataViewModel.BRAND.detailData.uid;
    },
    vaild(){
      const validData = this.mappingViewModel.dataViewModel.BRAND[this.isModify ? 'modifyValidData' : 'registerValidData']
      return pageValidated(validData, this.dataViewModel);
    },
  },
  beforeMount(){
    if(this.isModify){
      this.dataViewModel.setData(this.mappingViewModel.dataViewModel.BRAND.detailData);
    }
  },
  methods:{
    onClickPopupComplete(){
      if(this.dataViewModel.brand_logo_img_list.length > 0 && this.dataViewModel.brand_logo_img_list[0].file){
        this.onSave();
      }else{
        this.onCompleteFileSave();
      }
    },
    onCompleteFileSave(){
      this.$emit('onClickPopupComplete',this.isModify ? this.dataViewModel.getModifyData() : this.dataViewModel.getRegisterData())
    }
  }
}
</script>